<template>
  <div class="home">
    <div class="banner-bg">
      <img :src="bannerImg" />
      <div class="banner">
        <!-- <img :src="bannerImg" /> -->
        <!-- 学校名称 -->
        <div class="school-box">
          <div class="welcome">家长学校活动</div>
          <div class="school-name">芜湖师范幼教集团组织</div>
        </div>
      </div>
    </div>

    <div class="my-page">
      <template v-if="parentSchoolList.length > 0">
        <div
          class="notice"
          v-for="(item, index) in parentSchoolList"
          :key="index"
        >
          <div v-if="item.ParentSchoolDtoList.length > 0">
            <div class="my-title">
              <div class="title-label">
                <span class="span">{{ item.ModuleName }}</span>
                <span class="line"></span>
              </div>
              <div class="title-go" style="z-index:100" @click="goNoticeAll(item.ModuleId)">
                查看全部 >
              </div>
            </div>
            <div v-if="index % 2 === 0" class="notice-item">
              <div
                class="my-card"
                v-for="(childrenItem, i) in item.ParentSchoolDtoList"
                :key="i"
                @click="goNoticeView(childrenItem)"
              >
                <div class="card-main">
                  <div class="card_left">
                    <p class="title">{{ childrenItem.Title }}</p>
                    <p class="desc">{{ childrenItem.Desc }}</p>
                    <div>
                      <span v-if="childrenItem.LabelName" class="label">{{
                        childrenItem.LabelName
                      }}</span>
                      <span class="date">{{ childrenItem.Date }}</span>
                    </div>
                  </div>
                  <div class="card_right">
                    <img
                      :src="
                        childrenItem.TitleImage
                          ? childrenItem.TitleImage
                          : defaultImg
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="noticeConent" v-if="index % 2 === 1">
              <template>
                <div
                  class="my-card"
                  v-for="(childrenItem, index) in item.ParentSchoolDtoList"
                  :key="index"
                  @click="goNoticeView(childrenItem)"
                >
                  <div class="top">
                    <img
                        :src="
                          childrenItem.TitleImage
                            ? childrenItem.TitleImage
                            : defaultImg
                        "
                        
                      />
                  </div>
                  <div class="bottom" style="margin-top: 10px; font-size: 10px">
                    <span class="name">{{ childrenItem.Title}}</span>
                  </div>
                  <div
                    class="bottom_date"
                    style="margin-top: 6px; font-size: 10px"
                  >
                    <span class="date">{{ childrenItem.Date }}</span>
                  </div>
                </div>
              </template>
            </div>
           
          </div>
        </div>
      </template>
      <van-empty description="暂无数据" v-else />
    </div>
  </div>
</template>
<script>
import bannerImg from "@/assets/home/parentSchool.png";
import bannerBgImg from "@/assets/img/green-banner.png";
import defaultImg from "@/assets/img/defaultImg.png";
import { Empty } from "vant";
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      defaultImg: defaultImg,
      bannerBgImg: bannerBgImg,
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      userType: window.localStorage.getItem("UserType"),
      bannerImg: bannerImg,
      parentSchoolList: [],
    };
  },
  mounted() {
    // 获取通知公告
    this.getParentSchool();
  },
  methods: {
    getParentSchool() {
      this.$axios
        .get("/api/ParentSchool/parentSchool-parentList", {
          page: 1,
          limit: 3,
        })
        .then((res) => {
          this.parentSchoolList = res.data;
        });
    },
    goNoticeAll(moduleId) {
      this.$router.push({
        path: "ParentSchoolList",
        query: {
          moduleId: moduleId,
        },
      });
    },
    goNoticeView(item) {
      this.$router.push({
        path: "ParentSchoolView",
        query: {
          id: item.Id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
.van-swipe-item {
  margin: 0px 10px;
}
</style>
